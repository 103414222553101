<template>
  <div>
    <div class="tab">
      <el-tabs v-model="form.type" @tab-click="handleClick">
        <el-tab-pane label="请假" name="10"></el-tab-pane>
        <el-tab-pane label="补卡" name="20"></el-tab-pane>
        <el-tab-pane label="换班" name="40"></el-tab-pane>
      </el-tabs>
    </div>
    <div style="margin-top: 10px;">
      <div class="card" style='display: flex;'  :style="{ height: height }">
        <div class="left">
          <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2'>导出</el-button>
        </div>
        <div class='line'></div>
        <div class="middle">
          <el-form :model="form" :inline="true">
            <el-form-item label="提交时间">
              <el-date-picker
                v-model="form.create_time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="门店">
            <el-select v-model="form.hierarchy_id" placeholder="请选择门店"  filterable  clearable>
              <template v-for="item in merchantList">
                <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
            <el-form-item label="审批状态">
              <el-select v-model="form.approval_status" placeholder="请选择审批状态">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="审批中" :value="10"></el-option>
                <el-option label="已通过" :value="20"></el-option>
                <el-option label="已驳回" :value="30"></el-option>
                <el-option label="已撤销" :value="40"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="请假类型" v-if="form.type === '10'">
              <el-select v-model="form.holiday_type" placeholder="请选择请假类型">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="调休" :value="10"></el-option>
                <el-option label="年假" :value="20"></el-option>
                <el-option label="事假" :value="30"></el-option>
                <el-option label="病假" :value="40"></el-option>
                <el-option label="婚假" :value="50"></el-option>
                <el-option label="产假" :value="60"></el-option>
                <el-option label="陪产假" :value="70"></el-option>
                <el-option label="其他" :value="80"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请人">
              <el-input placeholder="请输入申请人" v-model="form.user_name" @keydown.enter.native="onSubmit"></el-input>
            </el-form-item>
            <el-form-item label="请假时间" v-if="form.type === '10'">
              <el-date-picker
                v-model="form.holiday_time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  @click="resetForm">重置</el-button>
            </el-form-item> -->
          </el-form>
          <div style="margin: 0 10px 0 auto">
            <el-button
              @click="handleHeight"
              style="
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                padding: 0;
              "
            >
              <i class="el-icon-caret-bottom" v-if="height === '40px'"></i>
              <i class="el-icon-caret-top" v-else></i>
            </el-button>
          </div>
            <div style="display: flex">
              <el-button type="primary"  @click="onSubmit" style="height: 40px"
                >搜索</el-button
              >
              <el-button  @click="resetForm" style="height: 40px;">重置</el-button>
          </div>
        </div>
      </div>
      <div class="table" v-if="rowList.length">
        <el-table
          :data="tableData.list"
          style="width: 100%"
          height="640"
          stripe
          v-loading="loading"
          @row-dblclick="handleDalclick"
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
            </el-table-column>
          </template>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleLook(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="$store.state.pageSizes"
          :page-size="$store.state.pageSizes[0]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total">
        </el-pagination>
      </div>
      <select-department :dialogVisible="dialogVisible" @onHideDepartment="handleHideDepartment" @onSelect="handleSelect" />
      <el-dialog
        title="审批详情"
        :visible.sync="lookVisible"
        width="30%"
        :before-close="handleClose">
        <div class="userInfo">
          <div class="avatar">
            <img :src="detail.avatar" v-if="detail.avatar" />
            <img v-else src="../../../../assets/images/no_img.png" />
          </div>
          <div class="name">{{detail.realname}}的{{form.type === '10'? '请假': form.type === '20'? '补卡': form.type === '40'? '换班': ''}}</div>
          <el-tag v-if="detail.approval_status === 10">审批中</el-tag>
          <el-tag v-if="detail.approval_status === 20" type="success">审批完成</el-tag>
          <el-tag v-if="detail.approval_status === 30" type="info">撤销</el-tag>
          <el-tag v-if="detail.approval_status === 40" type="danger">驳回</el-tag>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <div class="detail-title">审批详情</div>
          <div class="list">
            <div v-for="(item,index) in detailList" :key="index" style="display:flex; padding: 10px 0;">
              <div class="left">{{item.txt}}</div>
              <div class="right" v-if="item.prop !== 'holiday_images'">{{detail[item.prop] || '--'}}</div>
              <div class="right" v-else>
                 <el-image
                  v-for="(item,index) in detail[item.prop]"
                  :key="index"
                  style="width: 100px; height: 100px;"
                  fit="cover"
                  :src="item"
                  :preview-src-list="detail.holiday_images">
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="fow">
          <div class="fow-title">审批流程</div>
          <div class="list">
             <el-timeline>
               <template v-for="item in detail.approval_flow">
                 <el-timeline-item
                 :type="'primary'"
                 :timestamp="item.name + ' ' + (item.approval_status === 10? '审批中': item.approval_status === 20? '已同意': item.approval_status === 30? '已驳回': item.approval_status === 40? '已转审': '')"
                 placement="top" :key="item.approval_node_id">
                  <el-card>
                    <template v-for="approver in item.approver">
                      <div class="item" :key="approver.audit_store_user_id">
                        <div class="avatar">
                          <img :src="approver.avatar" v-if="approver.avatar" />
                          <i class="el-icon-s-custom" v-else style="font-size: 35px; color: #666;"></i>
                        </div>
                        <div class="desc">
                          <div class="name">
                            <span>{{approver.group_name + '：' + approver.realname}}</span>
                            <span>{{approver.status === 10? '审批中': approver.status === 20? '已同意': approver.status === 30? '已驳回': approver.status === 40? '已转审': '--'}} {{approver.audit_time}}</span>
                          </div>
                          <div>备注：{{approver.reason || '--'}}</div>
                        </div>
                      </div>
                    </template>
                  </el-card>
                </el-timeline-item>
               </template>
            </el-timeline>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="lookVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getMerchantListReq } from '@/api/authority/config';
import { myApplyApprovalReq, getApprovalDetailReq, getListforErpReq, getApprovalChangeReq } from '@/api/work/examine';
import SelectDepartment from '../SelectDepartment.vue';

export default {
  data() {
    return {
      offsetHeight: "106px",
      height: "40px",
      merchantList: [],
      detailList: [],
      detailList1: [
        { txt: '审批编号', prop: 'approval_no' },
        { txt: '提交时间', prop: 'create_time' },
        { txt: '所在门店', prop: 'hierarchy_id_text' },
        { txt: '请假类型', prop: 'holiday_type' },
        { txt: '开始时间', prop: 'start_time' },
        { txt: '结束时间', prop: 'end_time' },
        { txt: '请假时长', prop: 'duration' },
        { txt: '请假原因', prop: 'holiday_reason' },
        { txt: '请假图片', prop: 'holiday_images' },
      ],
      detailList2: [
        { txt: '审批编号', prop: 'approval_no' },
        { txt: '提交时间', prop: 'create_time' },
        { txt: '所在门店', prop: 'hierarchy_id_text' },
        { txt: '异常状态', prop: 'exception_status' },
        { txt: '补卡时间', prop: 'reapply_times' },
        { txt: '补卡原因', prop: 'reapply_reason' },
      ],
      detailList3: [
        { txt: '审批编号', prop: 'approval_no' },
        { txt: '提交时间', prop: 'create_time' },
        { txt: '所在门店', prop: 'hierarchy_id_text' },
        { txt: '换班申请人', prop: 'realname' },
        { txt: '换班对象', prop: 'change_store_user_id' },
        { txt: '换班日期', prop: 'change_day' },
        { txt: '还班日期', prop: 'refund_day' },
        { txt: '换班原因', prop: 'change_reason' },
      ],
      detail: {},
      loading: false,
      currRow: {}, // 当前行
      lookVisible: false, // 查看弹窗出现标识
      label: '',
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      form: {
        type: '10',
      },
      dialogVisible: false,
      rowList: [],
      rowList1: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '审批编号', field_alias: 'approval_no', t_width: 300 },
        { field_text: '提交时间', field_alias: 'create_time', t_width: 200 },
        { field_text: '申请人', field_alias: 'realname' },
        { field_text: '申请人门店', field_alias: 'hierarchy_id_text' },
        { field_text: '请假类型', field_alias: 'holiday_type' },
        { field_text: '请假时间', field_alias: 'holiday_time', t_width: 300 },
        { field_text: '请假时长(h)', field_alias: 'duration' },
        { field_text: '审批状态', field_alias: 'approval_status_text' },
      ],
      rowList2: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '审批编号', field_alias: 'approval_no', t_width: 300 },
        { field_text: '提交时间', field_alias: 'create_time', t_width: 200 },
        { field_text: '申请人', field_alias: 'realname' },
        { field_text: '申请人门店', field_alias: 'hierarchy_id_text' },
        { field_text: '补卡时间', field_alias: 'reapply_times_text' },
        { field_text: '审批状态', field_alias: 'approval_status_text' },
      ],
      rowList3: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '审批编号', field_alias: 'approval_no', t_width: 300 },
        { field_text: '提交时间', field_alias: 'create_time', t_width: 200 },
        { field_text: '申请人', field_alias: 'realname' },
        { field_text: '申请人门店', field_alias: 'hierarchy_id_text' },
        { field_text: '审批状态', field_alias: 'approval_status_text' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    SelectDepartment,
  },
  created() {
    this.rowList = this.rowList1;
    this.getMerchantList();
    this.getList();
    // this.getListforErp();
  },
  methods: {
    handleDalclick(row) {
      this.handleLook(row);
    },
    handleHeight() {
      // if (parseInt(this.offsetHeight) <= 62) {
      //   this.$message.warning("没有更多条件");
      //   return;
      // }
      this.height = this.height === "40px" ? this.offsetHeight : "40px";
    },
    resetForm() {
      this.form = {
        type: this.form.type,
      };
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },
    // 换班列表
    getApprovalChange() {
      this.loading = true;
      getApprovalChangeReq({ ...this.form })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 请假列表
    getListforErp() {
      this.loading = true;
      getListforErpReq({ ...this.form })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 搜索
    onSubmit() {
      this.page = 1;
      if (this.form.type === '10') {
        this.getList();
      }
      if (this.form.type === '20') {
        this.getListforErp();
      }
      if (this.form.type === '40') {
        this.getApprovalChange();
      }
    },
    getMerchantList() {
      getMerchantListReq()
        .then((res) => {
          if (res.code === 1) {
            this.merchantList = res.data;
          }
        });
    },
    handleClick() {
      this.page = 1;
      this.page_num = 15;
      if (this.form.type === '10') {
        this.rowList = this.rowList1;
        this.getList();
      }
      if (this.form.type === '20') {
        this.rowList = this.rowList2;
        this.getListforErp();
      }
      if (this.form.type === '40') {
        this.rowList = this.rowList3;
        this.getApprovalChange();
      }
    },
    // 审批详情
    getApprovalDetail(approval_flow_id) {
      if (this.form.type === '10') {
        this.detailList = this.detailList1;
      }
      if (this.form.type === '20') {
        this.detailList = this.detailList2;
      }
      if (this.form.type === '40') {
        this.detailList = this.detailList3;
      }
      this.detail = {};
      getApprovalDetailReq(approval_flow_id)
        .then((res) => {
          if (res.code === 1) {
            res.data.holiday_images && (res.data.holiday_images = res.data.holiday_images.split(','));
            this.detail = res.data;
          }
        });
    },
    // 请假列表
    getList() {
      this.loading = true;
      myApplyApprovalReq({ ...this.form, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleLook(row) {
      this.lookVisible = true;
      this.currRow = row;
      this.getApprovalDetail(row.approval_flow_id);
    },
    // 弹窗关闭
    handleClose() {
      this.lookVisible = false;
    },
    // 选择部门子组件回调事件
    handleSelect(nodeList) {
      console.log(nodeList);
      if (nodeList.length === 1) {
        this.label = nodeList[0].label;
      } else {
        this.label = `${nodeList[0].label}等多个部门`;
      }
      this.form.select = 1;
    },
    handleHideDepartment() {
      this.dialogVisible = false;
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(page_num) {
      this.page_num = page_num;
      this.page = 1;
      this.getList();
    },
    // 导出
    handleExport() {},
    // 选择部门
    handleChoose() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  transition: all 0.3s;
}
.middle {
  display: flex;
}
.userInfo {
  display: flex;
  align-items: center;
  .avatar {
    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .name {
    margin: 0 10px;
  }
}
.detail {
  margin-bottom: 50px;
  &-title {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
      margin-left: 20px;
      .left {
        width: 150px;
      }
    }
}
.fow {
  &-title {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .list {
    margin-left: 20px;
    margin-top: 20px;
    .item {
      display: flex;
      padding: 10px 0;
      align-items: center;
      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        .name {
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .el-icon-arrow-down {
      font-size: 50px;
      margin-left: 13px;
    }
  }
}
/deep/ .el-tabs__nav-wrap::after {
  content: '';
  height: 1px;
}
.tab {
   background: white;
  border-radius: 6px;
  border: 1px solid #ddd;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
  background: #ddd;
}
/deep/ .el-tabs__item {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
</style>
