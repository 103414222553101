import { post } from '@/utils/request';

// 请假列表
const myApplyApprovalReq = (params) => post({
  url: '/store/oa.ApprovalHoliday/getListforErp',
  data: {
    ...params,
  },
});

// 审批详情
const getApprovalDetailReq = (approval_flow_id) => post({
  url: '/store/oa.ApprovalFow/approvalDetail',
  data: {
    approval_flow_id,
  },
});

// 补卡列表
const getListforErpReq = (params) => post({
  url: '/store/oa.ApprovalReapply/getListforErp',
  data: {
    ...params,
  },
});

// 换班列表
const getApprovalChangeReq = (params) => post({
  url: '/store/oa.ApprovalChange/getListforErp',
  data: {
    ...params,
  },
});

// 审批设置
const createReq = (params) => post({
  url: '/store/oa.WorkFlow/create',
  data: {
    ...params,
  },
});

// 审批流详情
const getDetailReq = (params) => post({
  url: '/store/oa.WorkFlow/detail',
  data: {
    ...params,
  },
});

export {
  myApplyApprovalReq,
  getApprovalDetailReq,
  getListforErpReq,
  getApprovalChangeReq,
  createReq,
  getDetailReq,
};
