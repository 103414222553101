import { post } from '@/utils/request';

const createReq = ({ title, hierarchy_id }) => post({
  url: '/store/authority.group/create',
  data: {
    title,
    hierarchy_id,
  },
});

const listReq = () => post({
  url: '/store/authority.group/list',
});

const editReq = ({ title, hierarchy_id, id }) => post({
  url: '/store/authority.group/edit',
  data: {
    title,
    hierarchy_id,
    id,
  },
});

const menuListReq = () => post({
  url: '/admin/purview.Node/menuList',
});

const setRoleReq = ({ id, role, tree }) => post({
  url: '/store/authority.group/setRole',
  data: {
    id,
    role,
    tree,
  },
});

// 权限配置
const roleConfReq = () => post({
  url: '/store/authority.group/roleConf',
});

// 选择门店
const getMerchantListReq = () => post({
  url: '/store/common.select/getMerchantList',
});

// 字段权限列表
const roleField = (data) => post({
  url: '/store/authority.group/roleField',
  data
})

// 设置字段权限列表
const setRoleField = (data) => post({
  url: '/store/authority.group/setRoleField',
  data
})
// 查询系统权限
const getMyAuth = (data) => post({
  url: 'store/authority.MyAuth/getMyAuth',
  data
})


export {
  createReq,
  listReq,
  editReq,
  menuListReq,
  setRoleReq,
  roleConfReq,
  getMerchantListReq,
  roleField,
  setRoleField,
  getMyAuth
};
