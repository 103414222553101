<template>
  <div>
    <el-dialog
      title="选择部门"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">
      <div class="content">
        <div class="left">
          <el-tree
            ref="tree"
            :data="data"
            show-checkbox
            node-key="id"
            :default-expanded-keys="[2, 3]"
            :props="defaultProps">
          </el-tree>
        </div>
        <!-- <el-divider direction="vertical"></el-divider>
        <div class="right">
          <div class="title">已选</div>
        </div> -->
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="getCheckedNodes">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nodeList: [], // 选择的部门节点
      data: [{
        id: 1,
        label: '深圳天比高',
        children: [{
          id: 4,
          label: '二级 1-1',
          children: [{
            id: 9,
            label: '三级 1-1-1',
          }, {
            id: 10,
            label: '三级 1-1-2',
          }],
        }],
      }, {
        id: 2,
        label: '一级 2',
        children: [{
          id: 5,
          label: '二级 2-1',
        }, {
          id: 6,
          label: '二级 2-2',
        }],
      }, {
        id: 3,
        label: '一级 3',
        children: [{
          id: 7,
          label: '二级 3-1',
        }, {
          id: 8,
          label: '二级 3-2',
        }],
      }],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCheckedNodes() {
      this.nodeList = this.$refs.tree.getCheckedNodes();
      this.$emit('onSelect', this.nodeList);
      this.handleClose();
    },
    handleClose() {
      this.$emit('onHideDepartment');
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tree-node__content {
  height: 30px;
}
.content {
  display: flex;
  .left {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    height: 500px;
  }
  .right {
    flex: 1;
    box-sizing: border-box;
    padding: 0 10px;
    overflow-x: auto;
    overflow-y: auto;
    .title {
      line-height: 25px;
      font-weight: bold;
      font-size: 15px;
    }
  }
  /deep/ .el-divider--vertical {
    height: auto;
  }
}
</style>
